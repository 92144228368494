import axios from 'axios';

export default {

    AddAdjective(adjective) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/Trait`, adjective, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.post(`http://mail:82/api/adjective/AddUser`, adjective)
    },

    GetAdjectives() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/Trait`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.get(`http://mail:82/api/adjective/Getadjective`)
    },

    DeleteAdjective(adjective) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/Trait/${adjective}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.delete(`http://mail:82/api/user/DeleteUser?adjective=${adjective}`);
    },

    EditAdjective(adjective, user) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/Trait/${adjective}` , user, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    //   return axios.put(`http://mail:82/api/user/EditUser`, user)
     },




 


}