import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Auth/Login.vue";



Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "login",
        component: Login,
    },

    {
        path: "/cars-received",
        name: "cars_received",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/CarsReceivedaaaa/index.vue"
            ),
    },
    {
        path: "/cars-received/:id/details",
        name: "cars_received_details",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/CarsReceivedaaaa/Details.vue"
            ),
    },

   

   
    

    {
        path: "/Dashboard",
        name: "car_Dashboard",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Dashboard.vue"
            ),
    },


    {
        path: "/types-of-cars",
        name: "types_of_cars",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/TypesOfCars/index.vue"
            ),
    },
    {
        path: "/classes-of-cars",
        name: "classes_of_cars",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/ClassesOfCar/index.vue"
            ),
    },

    {
        path: "/colors",
        name: "colors",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Colors/index.vue"
            ),
    },

    {
        path: "/drivers",
        name: "drivers",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Drivers/index.vue"
            ),
    },
    {
        path: "/managers",
        name: "managers",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Managers/index.vue"
            ),
    },

    {
        path: "/daily-trips",
        name: "daily_trips",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/DailyTrips/index.vue"
            ),
    },

    {
        path: "/long-trips",
        name: "long_trips",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/LongTrips/index.vue"
            ),
    },

    {
        path: "/cars",
        name: "cars",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Cars/index.vue"
            ),
    },

    {
        path: "/cars/:id/details",
        name: "cars_details",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Cars/Details.vue"
            ),
    },

    
    {
        path: "/locations",
        name: "locations",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Locations/index.vue"
            ),
    },


    {
        path: "/adjectives",
        name: "adjectives",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Adjectives/index.vue"
            ),
    },

    {
        path: "/sides",
        name: "sides",
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import (
                /* webpackChunkName: "dashboard" */
                "../views/Sides/index.vue"
            ),
    },


    





];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;