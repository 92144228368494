import axios from 'axios';

export default {

    AddDriver(driver) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/RecipientPerson`, driver , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.post(`http://mail:82/api/Driver/AddRecipientPerson`, driver)
    },

    GetDrivers() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/RecipientPerson` , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.get(`http://mail:82/api/Driver/GetDriver`)
    },

    DeleteDriver(driver) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/RecipientPerson/${driver}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.delete(`http://mail:82/api/RecipientPerson/DeleteRecipientPerson?driver=${driver}`);
    },

    EditDriver(driver, RecipientPerson) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/RecipientPerson/${driver}` , RecipientPerson, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    //   return axios.put(`http://mail:82/api/RecipientPerson/EditRecipientPerson`, RecipientPerson)
     },









    

    GetDriverForEdit(driver) {
        return axios.get(`/api/Driver/GetRecipientPersonForEdit?driver=${driver}`);
      //  return axios.get(`http://mail:82/api/Driver/GetRecipientPersonForEdit?driver=${driver}`);
     },



 


}