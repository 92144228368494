//import axios from 'axios';
import SecurityService from './SecurityService.js';
import DashboardService from './DashboardService.js';

import TypesOfCarsService from './TypesOfCarsService';
import ClassesOfCarService from './ClassesOfCarService';
import CarsService from './CarsService';
import ColorsService from './ColorsService';
import DriversService from './DriversService';
import DriverCarService from './DriverCarService';
import ManagersService from './ManagersService';
import LocationsService from './LocationsService';
import AdjectivesService from './AdjectivesService';
import SidesService from './SidesService.js';




export default {


    TypesOfCarsService: TypesOfCarsService,
    DriversService: DriversService,
    ColorsService: ColorsService,
    CarsService: CarsService,
    ClassesOfCarService: ClassesOfCarService,
    DriverCarService: DriverCarService,
    ManagersService: ManagersService,
    LocationsService: LocationsService,
    AdjectivesService: AdjectivesService,
    SidesService: SidesService,
    
    
    securityService: SecurityService,
    DashboardService: DashboardService,

}