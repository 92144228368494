import axios from 'axios';

export default {

    AddSide(side) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/ConcernedParty`, side, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.post(`http://mail:82/api/side/AddUser`, side)
    },

    GetSides() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/ConcernedParty`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.get(`http://mail:82/api/side/Getside`)
    },

    DeleteSide(side) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/ConcernedParty/${side}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.delete(`http://mail:82/api/user/DeleteUser?side=${side}`);
    },

    EditSide(side, user) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/ConcernedParty/${side}` , user, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    //   return axios.put(`http://mail:82/api/user/EditUser`, user)
     },




 


}