import axios from 'axios';

export default {

    AddLocation(location) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/Location`, location, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.post(`http://mail:82/api/Location/AddUser`, location)
    },

    GetLocations() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/Location`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.get(`http://mail:82/api/Location/GetLocation`)
    },

    DeleteLocation(Location) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/Location/${Location}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.delete(`http://mail:82/api/user/DeleteUser?Location=${Location}`);
    },

    EditLocation(Location, user) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/Location/${Location}` , user, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    //   return axios.put(`http://mail:82/api/user/EditUser`, user)
     },




 


}