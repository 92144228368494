import axios from 'axios';

export default {

    AddManager(user) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/User`, user  , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.post(`http://mail:82/api/user/AddUser`, user)
    },

    GetManagers() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/User` , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.get(`http://mail:82/api/user/Getuser`)
    },

    DeleteManager(UserId) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/User/${UserId}` , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.delete(`http://mail:82/api/user/DeleteUser?UserId=${UserId}`);
    },

    EditManager(UserId, user) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/User/${UserId}` , user , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    //   return axios.put(`http://mail:82/api/user/EditUser`, user)
     },









    

    GetuserForEdit(UserId) {
        return axios.get(`/api/user/GetUserForEdit?UserId=${UserId}`);
      //  return axios.get(`http://mail:82/api/user/GetUserForEdit?UserId=${UserId}`);
     },

    


    

 


}