import axios from 'axios';

export default {

    NumbersOfReports(id) {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/DashBords/GetTotal?ManagementId=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
        
     //   return axios.get(`http://mail:82/api/DashBords/GetTotal?ManagementId=${id}`);
    },
    
}