import axios from "axios";

export default {
  AddCar(car) {
    return axios.post(
      `https://organizing-transportation.gt-dev.ly/api/Vehicle`,
      car, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    }
    );
  },

  GetCars() {
    return axios.get(`https://organizing-transportation.gt-dev.ly/api/Vehicle` , {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    });
  },

  GetCarsNotReceived() {
    return axios.get(`https://organizing-transportation.gt-dev.ly/api/Vehicle/not-received` , {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    });
  },

  GetCarForEdit(CarId) {
    return axios.get(
      `https://organizing-transportation.gt-dev.ly/api/Vehicle/${CarId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    }
    );
  },

  AddTechnicalInspection(car) {
    return axios.post(
      `https://organizing-transportation.gt-dev.ly/api/TechnicalInspection`,
      car, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    }
    );
  },

  AddInsurance(car) {
    return axios.post(
      `https://organizing-transportation.gt-dev.ly/api/Insurance`,
      car, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    }
    );
  },







  EditCar(id, car) {
    return axios.patch(`https://organizing-transportation.gt-dev.ly/api/Vehicle/${id}`, car, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    })
 },

 DeleteCar(id) {
    return axios.delete(`https://organizing-transportation.gt-dev.ly/api/Vehicle/${id}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
        }
    });

},


 


};
