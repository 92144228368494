import Vue from "vue";

Vue.filter("car_stat_filter", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "جديدة";
    else if (status === 2) statusName = "متوسطة";
    else if (status === 3) statusName = "خردة ";
    else if (status === 4) statusName = "عاطلة ";
    else if (status === 5) statusName = "مملكة ";
    return statusName;
});

Vue.filter("type_of_use_filter", function(status) {
    if (status === null) return "-";
    let statusName = "";
    if (status === 1) statusName = "مسافاة طويله";
    else if (status === 2) statusName = "نقل عاملين";
    else if (status === 3) statusName = "نقل بضائع ";
    else if (status === 4) statusName = "الحركة ";
    else if (status === 5) statusName = "ضيافة ";
    else if (status === 6) statusName = "عهدة ";
    return statusName;
});