<template>
  <div>
    <div class="min-h-screen flex items-center justify-center bg-gray-50">
      <div class="w-full flex justify-between">
        <div class="w-2/3 flex flex-col justify-center items-center">
          <div class="max-w-lg mx-auto w-full">
            <div>
              <img
                class="mx-auto h-40 w-auto object-cover"
                src="../../assets/img/logo-aca.png"
                alt="logo"
              />
              <h2
                class="mt-6 text-center text-4xl font-extrabold text-gray-900"
              >
                منظومة تنظيم وسائل النقل التابعة للهيئة
              </h2>
            </div>

            <div v-on:keyup.enter="submit" class="mt-8 space-y-6">
              <div>
                <label
                  for="userName"
                  class="block text-base font-semibold text-gray-800"
                >
                  اسم المستخدم
                </label>

                <input
                  id="userName"
                  name="userName"
                  type="text"
                  autocomplete="userName"
                  required
                  v-model="userName"
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                />
              </div>

              <div>
                <label
                  for="password"
                  class="block text-base font-semibold text-gray-800"
                >
                  كلمه المرور
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="password"
                  required
                  v-model="Password"
                  class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                />
              </div>

              <div>
                <button
                  @click="submit()"
                  type="submit"
                  class="group relative w-full mx-auto flex justify-center py-3 px-4 border border-transparent shadow-sm text-sm font-bold rounded-md border-blue-600 text-white bg-blue-600 hover:shadow-lg focus:shadow-none duration-300 focus:outline-none"
                >
                  <span
                    class="absolute left-0 inset-y-0 flex items-center pl-3"
                  >
                    <svg
                      class="h-5 w-5 text-white fill-current"
                      id="_x31__x2C_5"
                      enable-background="new 0 0 24 24"
                      height="512"
                      viewBox="0 0 24 24"
                      width="512"
                    >
                      <path
                        d="m18.75 24h-13.5c-1.24 0-2.25-1.009-2.25-2.25v-10.5c0-1.241 1.01-2.25 2.25-2.25h13.5c1.24 0 2.25 1.009 2.25 2.25v10.5c0 1.241-1.01 2.25-2.25 2.25zm-13.5-13.5c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75z"
                      />
                      <path
                        d="m17.25 10.5c-.414 0-.75-.336-.75-.75v-3.75c0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75c0-3.309 2.691-6 6-6s6 2.691 6 6v3.75c0 .414-.336.75-.75.75z"
                      />
                      <path
                        d="m12 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5z"
                      />
                      <path
                        d="m12 20c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2.75c0 .414-.336.75-.75.75z"
                      />
                    </svg>
                  </span>
                  تسجيل الدخول
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="w-1/3">
          <img
            class="mx-auto h-screen w-full object-cover"
            src="../../assets/img/car.jpg"
            alt="logo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  mounted() {},

  watch: {},

  data() {
    return {
      userName: "",
      Password: "",
    };
  },
  methods: {
    totest() {
      console.log("IN");
      this.$router.push({ name: "car_Dashboard" });
    },

    submit() {
      var Login = {
        userName: this.userName,
        password: this.Password,
      };

      this.$http.securityService
        .Login(Login)
        .then((res) => {
   
          
          console.log(res.data.data)
          console.log(res.data.jwt)
          localStorage.setItem("A_L_1", JSON.stringify(res.data.data));
          localStorage.setItem("A_L_2", res.data.jwt);

          this.$router.push({ name: "car_Dashboard" });


          // localStorage.getItem("A.L")

          // console.log('res.data.jwt')
          // console.log('res.data.jwt')
          // console.log(localStorage.getItem("A_L_1"))
          // console.log(localStorage.getItem("A_L_2"))

        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
