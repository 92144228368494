import axios from 'axios';

export default {

    AddTypesOfCar(car) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/CarMake`, car , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    },

    GetTypesOfCars() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/CarMake` , {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    },



    EditTypesOfCar(id, CarMake) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/CarMake/${id}`, CarMake, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     },
    
     DeleteTypesOfCar(id) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/CarMake/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
    
    },

}