import axios from 'axios';

export default {

    AddColor(car) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/CarColor`, car, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.post(`http://mail:82/api/Color/AddUser`, car)
    },

    GetColors() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/CarColor`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.get(`http://mail:82/api/Color/GetColor`)
    },

    DeleteColor(color) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/CarColor/${color}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.delete(`http://mail:82/api/user/DeleteUser?color=${color}`);
    },

    EditColor(color, user) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/CarColor/${color}` , user, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    //   return axios.put(`http://mail:82/api/user/EditUser`, user)
     },









    

    GetColorForEdit(color) {
        return axios.get(`/api/Color/GetUserForEdit?color=${color}`);
      //  return axios.get(`http://mail:82/api/Color/GetUserForEdit?color=${color}`);
     },



 


}