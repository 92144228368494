import axios from 'axios';

export default {

    AddClassessOfCar(car) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/CarModel`, car, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.post(`http://mail:82/api/ClassessOfCar/AddUser`, car)
    },

    GetClassessOfCars() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/CarModel`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     //   return axios.get(`http://mail:82/api/ClassessOfCar/GetClassessOfCar`)
    },

    GetClassessOfCarsById(CarId) {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/CarMake/${CarId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.get(`http://mail:82/api/TypesOfCar/GetUserForEdit?UserId=${UserId}`);
     },









    

    GetClassessOfCarForEdit(UserId) {
        return axios.get(`/api/ClassessOfCar/GetUserForEdit?UserId=${UserId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.get(`http://mail:82/api/ClassessOfCar/GetUserForEdit?UserId=${UserId}`);
     },

    DeleteClassessOfCar(UserId) {
        return axios.delete(`/api/ClassessOfCar/DeleteUser?UserId=${UserId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.delete(`http://mail:82/api/ClassessOfCar/DeleteUser?UserId=${UserId}`);
    },


    EditClassessOfCar(car) {
        return axios.put(`/api/ClassessOfCar/EditUser`, car, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    //   return axios.put(`http://mail:82/api/ClassessOfCar/EditUser`, car)
     },

 


}