import axios from 'axios';

export default {
    Login(Login) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/user/login`, Login)
      //  return axios.post(`http://mail:82/api/Auth/LoginUser`, Login)
    },
    Logout() {
        return axios.post('https://organizing-transportation.gt-dev.ly/api/Security/Logout');
     //  return axios.post('http://mail:82/api/Security/Logout');
     },
    CheckLogin() {
       return axios.get('https://organizing-transportation.gt-dev.ly/api/Security/CheckLogin');
     //   return axios.get('http://mail:82/api/Security/CheckLogin');
     },
}