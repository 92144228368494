import axios from 'axios';

export default {

    AddDriverCar(car) {
        return axios.post(`https://organizing-transportation.gt-dev.ly/api/VehicleRecipientPerson`, car, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     
    },

    GetDriversCars() {
        return axios.get(`https://organizing-transportation.gt-dev.ly/api/VehicleRecipientPerson`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
    },


    EditDriversCar(id, car) {
        return axios.patch(`https://organizing-transportation.gt-dev.ly/api/VehicleRecipientPerson/${id}`, car, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        })
     },

     DeleteDriversCar(id) {
        return axios.delete(`https://organizing-transportation.gt-dev.ly/api/VehicleRecipientPerson/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
   
    },









    

    GetClassessOfCarForEdit(UserId) {
        return axios.get(`/api/ClassessOfCar/GetUserForEdit?UserId=${UserId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("A_L_2")}`
            }
        });
      //  return axios.get(`http://mail:82/api/ClassessOfCar/GetUserForEdit?UserId=${UserId}`);
     },

    


    

 


}